import React, { useState, useEffect } from "react"
import { Link } from "gatsby"
import PropTypes from "prop-types"
import "./Header.css"

const Header = ({ siteTitle }) => {
  const [stateHeader, changeStateHeader] = useState(false)

  useEffect(() => {
    window.addEventListener("scroll", handleScroll)

    // Specify how to clean up this effect
    return function cleanup() {
      window.removeEventListener("scroll", handleScroll)
    }
  }, [])

  const handleScroll = event => {
    const scrollTop = window.pageYOffset

    if (scrollTop > 50) {
      changeStateHeader(true)
    } else {
      changeStateHeader(false)
    }
  }

  return (
    <header className={stateHeader ? "Header HeaderScrolled" : "Header"}>
      <div className="HeaderGroup">
        <Link to="/">
          <img alt="" src={require("../images/logoverpar.svg")} width="30" />
        </Link>
        <Link activeClassName="active" to="/">
          Inicio
        </Link>
        <Link activeClassName="active" to="/projects">
          Proyectos
        </Link>
        <Link activeClassName="active" to="/ourteam">
          Nosotros
        </Link>
        <Link to="/contact">
          <button>Contacto</button>
        </Link>
      </div>
    </header>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
