import React from "react"
import Link from "gatsby-link"
import "./Footer.css"

// const Footer = ({data, children}) => (

// )
const Footer = props => (
  <div className="FooterGroup">
    <img alt="" className="SectionLogo" src={props.logo} />
    <p className="Text">VERPAR</p>
    <div className="LinkGroup">
      <div className="LinkItems">
        <Link to="/">Inicio</Link>
      </div>
      <div className="LinkItems">
        <Link to="/projects">Proyectos</Link>
      </div>
      <div className="LinkItems">
        <Link to="/ourteam">Nosotros</Link>
      </div>
      <div className="LinkItems">
        <Link to="/contact">Contacto</Link>
      </div>
    </div>
    <div className="SectionInfo">
      contacto@verpar.org.pe <br /> Calle Van Gogh 180, Dpto 101, San Borja{" "}
      <br />
      Lima, Perú
    </div>
  </div>
)

export default Footer
